import { iconConfigurationVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Icon/iconConfigurations';
import { IconWithLoading } from '@cfra-nextgen-frontend/shared/src/components/Icon/IconWithLoading';
import {
    getRequestParamsPropsVariant1,
    getRequestParamsPropsVariant2,
    researchLinkGetterParams,
} from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { tooltipThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { CellRendererParam } from '@cfra-nextgen-frontend/shared/src/components/types/fieldViewData';
import { TypographyStyle10 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { useLinkGetter } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { getValueByPath, getValuesByPath } from '@cfra-nextgen-frontend/shared/src/utils';
import { getCursorVariant1 } from '@cfra-nextgen-frontend/shared/src/utils/cursor';
import { getIcon } from '@cfra-nextgen-frontend/shared/src/utils/icons';
import { Box, Grid, ThemeProvider, Tooltip } from '@mui/material';

type LinkRetrieverProps = {
    resultValue: unknown;
    cellRendererParam?: CellRendererParam;
    type: string;
    iconName?: string;
    className?: string | Array<string>;
};

export function LinkRetriever({ resultValue, cellRendererParam, type, iconName, className }: LinkRetrieverProps) {
    const { setRequestParamsProps, isLoading, isError } = useLinkGetter(...researchLinkGetterParams);

    const itemData = cellRendererParam?.item_data;

    if (Array.isArray(resultValue) && resultValue.length !== 1) {
        throw new Error(
            'The result value for the LinkRetriever component received an array with more than one element.',
        );
    }

    const source = Array.isArray(resultValue) ? resultValue[0] : resultValue;

    switch (type) {
        case 'open_link':
            if (!itemData) {
                throw new Error('The itemData prop is required for the open_link type of the LinkRetriever component.');
            }

            return (
                <ThemeProvider theme={tooltipThemeV2}>
                    <Tooltip title={isError ? itemData?.errorTooltipText : itemData?.tooltipText} placement={'top'}>
                    <Box
                        sx={{
                            display: 'inline-flex',
                        }}>
                            <TypographyStyle10
                                sx={{
                                    '&:hover': {
                                        color: '#5A9CD1',
                                        cursor: getCursorVariant1(isLoading, isError),
                                    },
                                    lineHeight: 1,
                                }}
                                onClick={() => {
                                    setRequestParamsProps(
                                        getRequestParamsPropsVariant1(getValueByPath(source, itemData?.item_id_field)),
                                    );
                                }}>
                                {getValueByPath(source, itemData?.field) || cellRendererParam?.no_results_symbol}
                            </TypographyStyle10>
                        </Box>
                    </Tooltip>
                </ThemeProvider>
            );
        case 'svg_icon':
            const Icon = getIcon(iconName);
            const filterNamesToValues = itemData?.item_names_to_paths
                ?.map(({ item_name, item_path, item_value }) => ({
                    [item_name]: { values: item_value || getValuesByPath(source, item_path) },
                }))
                .reduce((acc, item) => ({ ...acc, ...item }), {});

            return (
                <ThemeProvider theme={tooltipThemeV2}>
                    <Tooltip title={isError ? itemData?.errorTooltipText : itemData?.tooltipText} placement={'top'}>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: getCursorVariant1(isLoading, isError)
                            }}>
                            <IconWithLoading
                                icon={Icon}
                                iconConfiguration={
                                    isError ? iconConfigurationVariant1.error : iconConfigurationVariant1.normal
                                }
                                onClick={() =>
                                    setRequestParamsProps(
                                        getRequestParamsPropsVariant2(filterNamesToValues, itemData?.request_params),
                                    )
                                }
                                isLoading={isLoading}
                                iconSx={{
                                    cursor: 'pointer'
                                }}
                            />
                        </Grid>
                    </Tooltip>
                </ThemeProvider>
            );
        default:
            throw new Error(`The type ${type} is not supported by the LinkRetriever component.`);
    }
}
