import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { useGesture } from 'react-use-gesture';
import './AgGrid.scss';
import './paddings.scss';
import { scrollOneColumnTo } from './utils';

type AgGridDragScrollProps = {
    gridRef: React.RefObject<AgGridReact<any>>;
    gridContainerRef: React.RefObject<HTMLDivElement>;
    inverseScrollDirection?: boolean;
};

export type AgGridDragScrollRef = {
    refreshListeners: () => void;
};

const SCROLL_DELAY = 100; // scroll delay in ms

export const AgGridDragScroll = forwardRef<AgGridDragScrollRef, AgGridDragScrollProps>(
    ({ gridRef, gridContainerRef, inverseScrollDirection }, ref) => {
        const isMoveCallbackBlockedRef = useRef<boolean>(false);
        const areListenersAddedRef = useRef<boolean>(false);

        const bind = useGesture({
            onDrag: ({ movement: [mx, my], direction: [dx, dy], event }) => {
                if (isMoveCallbackBlockedRef.current) {
                    return;
                }

                isMoveCallbackBlockedRef.current = true;

                const isHorizontalDrag = Math.abs(dx) > Math.abs(dy);

                if (isHorizontalDrag) {
                    const isDragRight = inverseScrollDirection ? dx < 0 : dx > 0;

                    scrollOneColumnTo(isDragRight ? 'right' : 'left', gridRef);
                } else {
                    // if not horizontal drag, then it's vertical drag - handle it below
                    window.scrollBy({
                        top: inverseScrollDirection ? -my : my,
                        behavior: 'smooth',
                    });
                }

                setTimeout(() => {
                    isMoveCallbackBlockedRef.current = false;
                }, SCROLL_DELAY);
            },
        });

        const setDragListeners = useCallback(() => {
            if (!gridRef.current || !gridContainerRef.current || areListenersAddedRef.current) {
                return;
            }

            const viewportElement = gridContainerRef.current.querySelector(
                '.ag-center-cols-viewport',
            ) as HTMLDivElement;

            if (!viewportElement) {
                return;
            }

            const {
                onPointerDown,
                onPointerMove,
                onPointerUp,
            } = bind();

            const handleOnPointerUp = (event: MouseEvent) => {
                if (event.button !== 0) {
                    return;
                }

                const rootElement = document.querySelector('#root') as HTMLDivElement;
                if (rootElement) {
                    rootElement.style.cursor = 'unset';
                }

                onPointerUp?.(event as any);
            };

            const handleOnMouseDown = (event: MouseEvent) => {
                if (event.button !== 0) {
                    return;
                }

                const rootElement = document.querySelector('#root') as HTMLDivElement;
                if (rootElement) {
                    rootElement.style.cursor = 'all-scroll';
                }

                onPointerDown?.(event as any);
            };

            viewportElement.addEventListener('pointerdown', handleOnMouseDown as any);
            viewportElement.addEventListener('pointermove', onPointerMove as any);
            viewportElement.addEventListener('pointerup', handleOnPointerUp as any);

            areListenersAddedRef.current = true;
            return () => {
                viewportElement.removeEventListener('pointerdown', handleOnMouseDown as any);
                viewportElement.removeEventListener('pointermove', onPointerMove as any);
                viewportElement.removeEventListener('pointerup', handleOnPointerUp as any);

                areListenersAddedRef.current = false;
            };
        }, [gridContainerRef, gridRef, bind]);

        useImperativeHandle(ref, () => ({
            refreshListeners: () => {
                setDragListeners();
            },
        }));

        return null;
    },
);
