import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ColDef, Column } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ColumnDef } from './types';

export function setCustomFlexWidths(isBelowLg: boolean, customFlexibleColumns: Array<string>) {
    return (gridRef: React.RefObject<AgGridReact>) => {
        if (!gridRef.current) return;
        const getWidth = (minWidth: number, maxWidth: number, allColumnsNames: Array<string>) => {
            if (isBelowLg) minWidth = 170;
            const columnsIntersection = allColumnsNames.filter((x) => customFlexibleColumns.includes(x));
            let width = isBelowLg
                ? maxWidth -
                  (breakpointsTheme.breakpoints.values['lg'] - globalThis.window.innerWidth) /
                      columnsIntersection.length
                : maxWidth;

            if (width < minWidth) return minWidth;
            if (width > maxWidth) return maxWidth;
            return width;
        };
        const columnDefs = gridRef.current?.api.getColumnDefs() as Array<ColDef>;
        if (!columnDefs) return;
        const allColumnsNames: Array<string> = [];
        columnDefs.forEach((element) => {
            if (element.headerName) {
                allColumnsNames.push(element.headerName);
            }
        });
        gridRef.current?.api.setColumnDefs(
            columnDefs.map((columnDef) => {
                if (
                    !(
                        columnDef.headerName &&
                        columnDef.minWidth &&
                        columnDef.maxWidth &&
                        allColumnsNames.length > 0 &&
                        customFlexibleColumns.includes(columnDef.headerName)
                    )
                ) {
                    return columnDef;
                }

                return {
                    ...columnDef,
                    width: getWidth(columnDef.minWidth, columnDef.maxWidth, allColumnsNames),
                };
            }),
        );
    };
}

export function Link(props: { value: string; handleOpen?: () => void }) {
    // use eslint-disable-next-line to avoid the warning - The href attribute is required for an anchor to be keyboard accessible.
    // we don't need href here, use <a>, but not <button> to same ag grid ellipsis behavior
    return (
        // eslint-disable-next-line
        <a
            onClick={props.handleOpen}
            style={{
                color: '#007bb8',
                cursor: 'pointer',
            }}>
            {props.value}
        </a>
    );
}

export function agGridGetRenderedRowsCount(grid: AgGridReact) {
    return grid.api.getRenderedNodes().length;
}

export function agGridGetAllRowsCount(grid: AgGridReact) {
    return grid.api.getModel().getRowCount();
}

export function getExportColumnKeys(columnDefs: Array<ColumnDef>): Array<string> {
    return columnDefs.filter((p) => p.headerName && p.headerName !== '' && !p.ignoreOnExport).map((p) => p.field || '');
}

export enum AgGridThemes {
    GridThemeV2 = 'grid-theme-v2',
}

const classNameToDefaultStyle: Record<AgGridThemes, ColDef> = {
    [AgGridThemes.GridThemeV2]: {
        cellStyle: { paddingLeft: 14, paddingRight: 14 },
        headerClass: ['horizontal-padding-14'],
    } as ColDef,
};

function getAgGridThemeFromClassName(className: string): AgGridThemes | undefined {
    const classes = className.split(' ');
    return Object.keys(classNameToDefaultStyle).find((key) => classes.includes(key)) as AgGridThemes | undefined;
}

export function getDefaultStyleFromClassName(className: string): ColDef {
    const key = getAgGridThemeFromClassName(className);

    if (!key) {
        return {};
    }

    return classNameToDefaultStyle[key as AgGridThemes];
}

export function getDefaultColDef({
    className,
    defaultMaxWidth,
}: {
    className: string;
    defaultMaxWidth?: number | null;
}): ColDef {
    const colDef = {
        sortable: true,
        filter: true,
        resizable: true,
        autoHeight: true,
        menuTabs: [],
        ...getDefaultStyleFromClassName(className),
    };

    return {
        ...colDef,
        ...(defaultMaxWidth && { maxWidth: defaultMaxWidth }),
    };
}

export function getClassNameValue(gridTheme?: Array<string> | string) {
    let classNames = ['ag-theme-alpine'];

    if (!gridTheme) {
        return classNames.join(' ');
    }

    if (Array.isArray(gridTheme)) {
        classNames = [...classNames, ...gridTheme];
    } else {
        classNames.push(gridTheme);
    }

    return classNames.join(' ');
}

export function getVisibleColumns(
    gridRef: React.RefObject<AgGridReact<any>>,
    mode: 'fully_visible' | 'partially_visible' = 'fully_visible',
): Array<Column> | null {
    // Get all displayed columns
    const allDisplayedColumns = gridRef?.current?.columnApi.getAllDisplayedColumns();

    // Get the current horizontal pixel range of the visible area
    const horizontalPixelRange = gridRef?.current?.api.getHorizontalPixelRange();

    if (!allDisplayedColumns || !horizontalPixelRange) {
        return null;
    }

    const viewportLeft = horizontalPixelRange.left;
    const viewportRight = horizontalPixelRange.right;

    // Filter columns based on their pixel positions within the viewport range
    const visibleColumns = allDisplayedColumns.filter((column: any) => {
        const columnBorderLeft = column.getLeft();
        const columnBorderRight = columnBorderLeft + column.getActualWidth();

        const isInVisibleArea = (value: number) => {
            const precisionError = 1; // we need to include one more pixel to the viewportRight to be able to assume the column is fully visible after ensureColumnVisible call
            return viewportLeft <= value && viewportRight + precisionError >= value;
        };

        const isLeftColumnBorderVisible = isInVisibleArea(columnBorderLeft);
        const isRightColumnBorderVisible = isInVisibleArea(columnBorderRight);

        return mode === 'fully_visible'
            ? isLeftColumnBorderVisible && isRightColumnBorderVisible
            : isLeftColumnBorderVisible || isRightColumnBorderVisible;
    });

    return visibleColumns;
}

export function getClosestNotVisibleColumn(
    gridRef: React.RefObject<AgGridReact<any>>,
    position: 'next' | 'previous',
): Column | null {
    const gridRefCurrent = gridRef.current;

    if (!gridRefCurrent) {
        return null;
    }

    const columnApi = gridRefCurrent.columnApi;
    const allColumns = columnApi.getAllDisplayedColumns();

    const visibleColumns = getVisibleColumns(gridRef, 'fully_visible');

    if (!allColumns || !visibleColumns || visibleColumns.length === 0) {
        return null;
    }

    // Determine the column position to find the next or previous column
    const firstVisibleColumn = visibleColumns[0];
    const lastVisibleColumn = visibleColumns[visibleColumns.length - 1];

    if (position === 'next') {
        // Find the index of the last visible column in the allColumns array
        const lastVisibleIndex = allColumns.indexOf(lastVisibleColumn);

        // Check if there's a column immediately after it
        return lastVisibleIndex < allColumns.length - 1 ? allColumns[lastVisibleIndex + 1] : null;
    } else if (position === 'previous') {
        // Find the index of the first visible column in the allColumns array
        const firstVisibleIndex = allColumns.indexOf(firstVisibleColumn);

        // Check if there's a column immediately before it
        return firstVisibleIndex > 0 ? allColumns[firstVisibleIndex - 1] : null;
    }

    throw new Error('Invalid position provided');
}

export function scrollOneColumnTo(direction: 'left' | 'right', gridRef: React.RefObject<AgGridReact<any>>) {
    if (!gridRef.current) {
        return;
    }

    const closestNonVisibleColumn = getClosestNotVisibleColumn(
        gridRef,
        direction === 'right' ? 'next' : 'previous',
    )?.getColId();

    if (!closestNonVisibleColumn) {
        return;
    }

    gridRef.current?.api.ensureColumnVisible(closestNonVisibleColumn, direction === 'right' ? 'end' : 'start');
}
